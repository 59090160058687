.footer_home {
  background-color: #00823d;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: space-around;
}

.footer_div {
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.logo_branca {
  width: 200px;
}

.logo22000 {
  width: 120px;
}

.p_footer {
  display: flex;
  gap: 5px;
  justify-content: center;
}



.footer_div_imagens {
  display: flex;
  flex-direction: row !important;
  gap: 25px;
}

.titulo_footer {
  font-size: 18px;
}

.footer_div div a {
  display: flex;
  gap: 5px;
  align-items: center;
}

.link_footer {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.seta {
  position: absolute;
  font-size: 20px;
  margin-right: 5px;
  opacity: 0;
  transition: 0.5s;
}

.seta2 {
  font-size: 20px;
  transition: opacity 0.3s ease;
  transition: 0.5s;
  margin-right: 5px;
}

.link_footer:hover .seta {
  display: block;
  position: inherit;
  opacity: 1;
}

.link_footer:hover .seta2 {
  position: absolute;
  opacity: 0;
}

.div_mapa_inst {
  display: flex;
  gap: 100px;
}

@media (max-width: 1000px) {
  .footer_div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .footer_home {
    height: 100%;
  }
  .footer_div_imagens {
    flex-direction: column !important;
    align-items: center;
    margin: 50px 0;
  }
  .div_mapa_inst {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .div_mapa_inst {
    font-size: 15px;
    gap: 30px;
  }
  .div_mapa_inst div {
    width: 50%;
  }
}