.videocontainer {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.videobg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.secao1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  color: white;
  z-index: 2;
  height: 400px;
  padding-top: 20vh;
}

.h1 {
  font-size: 8vh;
  width: 70%;
  text-align: center;
  margin: auto;
  margin-top: 100px;
  font-weight: 500;
  line-height: 1;
}

.p_secao1 {
  text-align: center;
  font-size: 22px;
  width: 40%;
  margin: auto;
  color: #eedab3;
}

.botao_conheca {
  background-color: var(--vermelho);
  margin: auto;
  color: #fff;
  padding: 20px 50px;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.2s;
}

.botao_conheca:hover {
  background-color: #df231dc9;
}

/* Estilo para o SVG */
@keyframes draw {
  from {
    stroke-dasharray: 0, 1000;
    opacity: 1;
  }
  to {
    stroke-dasharray: 1000, 0;
    opacity: 1;
  }
}

.svg path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  opacity: 0;
  animation: draw 1.2s ease-in-out forwards;
  animation-delay: 1s;
}

.svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  pointer-events: none;
  z-index: 0;
  overflow: visible;
}

.spanWrapper {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  overflow: visible;
  padding: 20px;
}

@media (max-width: 1100px) {
  .h1 {
    width: 90%;
  }
  .p_secao1 {
    width: 60%;
  }
}

@media (max-width: 850px) {
  .h1 {
    font-size: 6vh;
    margin-top: 50px;
  }
  .p_secao1 {
    width: 80%;
    font-size: 18px;
  }
}
