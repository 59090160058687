@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

/* SEÇÃO 1 */

@keyframes zoomReverso {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.main_cert {
  background-color: #fff;
}

.c_s1 {
  background-image: url("../../assets/img/c_s1_img.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  display: flex;
  align-items: start;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  padding-left: 8%;
  position: relative;
  overflow: hidden;
  animation: zoomReverso 1s ease-out forwards;
  width: 100%;
  gap: 30px;
}

.c_s1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-position: center;
  background-repeat: inherit;
  background-size: cover;
  z-index: -1;
}

.c_s1 h1 {
  font-size: 8vh;
  font-weight: 500;
}

.c_s1 p {
  font-size: 3vh;
  width: 90%;
}

.svg_seta {
  background-color: var(--vermelho);
  padding: 15px;
  border-radius: 50%;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.h1_cert,
.p_cert {
  animation: fadeInUp 0.8s ease-out;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* SEÇÃO 2 */

.c_s2 {
  display: flex;
  justify-content: center;
  gap: 10%;
  align-items: end;
}

.c_s2_div {
  width: 40%;
  margin-left: 5%;
}

.c_s2 img {
  width: 35%;
}

.c_s2_div p {
  color: #3d3d3d;
  font-weight: 200;
  margin: 10% 0;
  font-size: 2.5vh;
}

.img_cert_mobile {
  display: none;
}

/* RESPONSIVIDADE SEÇÃO 1: */
@media (max-width: 480px) {
  .c_s1 h1 {
    font-size: 7vh;
  }
}
@media (max-width: 400px) {
  .c_s1 h1 {
    font-size: 6vh;
  }
}

/* RESPONSIVIDADE SEÇÃO 2: */
@media (max-width: 1080px) {
  .img_cert_mobile {
    display: block;
  }
  .img_cert {
    display: none;
  }

  .c_s2 {
    flex-direction: column;
    align-items: center;
  }

  .c_s2_div {
    width: 80%;
    margin-left: 0;
  }

  .c_s2 img {
    width: 50%;
    margin-bottom: 10%;
  }
}
@media (max-width: 680px) {
  .c_s2 img {
    width: 80%;
    margin-bottom: 10%;
  }
  .c_s2_div p {
    text-align: justify;
  }
}
@media (max-width: 470px) {
  .c_s2_div p {
    font-size: 18px;
  }
}