@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

.main_home {
  background-color: #faf6f0;
}

/* SEÇÃO 2 */

.secao2 {
  display: flex;
  margin: 100px 0;
  justify-content: center;
  gap: 10%;
}

.div_textos_s2 {
  width: 30%;
  color: #6e6e6e;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  font-size: 17px;
  font-weight: 300;
}

.secao2 img {
  width: 600px;
  height: 600px;
  border-radius: 20px;
}

.titulo_s2 {
  color: var(--verde);
  font-size: 40px;
  font-weight: 400;
  margin: -15px 0;
}

.botao_saibamais_s2 {
  background-color: var(--vermelho);
  color: #fff;
  padding: 20px 40px;
  border-radius: 5px;
  font-weight: 700;
  transition: 0.2s;
}

.botao_saibamais_s2:hover {
  background-color: #eb2f29;
}

/* SEÇÃO 3 */

.secao3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px;
  gap: 20px;
}

.secao3 h1 {
  font-size: 50px;
  width: 60%;
  text-align: center;
  margin-bottom: 20px;
  color: var(--verde);
}

.div_s3 {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.div1_s3,
.div2_s3,
.div3_s3 {
  height: 300px;
  width: 30%;
  border-radius: 15px;
  padding: 50px 40px;
}

.div1_s3 {
  background-color: #fff;
  box-shadow: 0 10px 10px 5px #cccccc50;
}

.div2_s3 {
  background-color: var(--verde);
  box-shadow: inset 0 0 10px 5px #017742;
  color: #fff;
}

.div3_s3 {
  background-color: #df0022;
  color: #fff;
}

.titulo_div1_s3,
.titulo_div2_s3,
.titulo_div3_s3 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 10px;
}

.titulo_div1_s3 {
  color: var(--verde);
  font-weight: 500;
}

.p_div1_s3 {
  color: var(--verde);
  font-weight: 300;
}

.p_div2_s3,
.p_div3_s3 {
  font-weight: 300;
}

/* SEÇÃO 4 */

.secao4 {
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  overflow: hidden;
}

.secao4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/banner_legumes.png");
  background-repeat: no-repeat;
  background-position: center var(--bg-position, 100%);
  background-size: cover;
  filter: brightness(60%);
  z-index: 1;
  transition: background-position 0.1s linear;
}

.secao4 p {
  position: relative;
  color: #fff;
  font-size: 40px;
  z-index: 2;
}

.span_s4 {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 0 10px;
}

.span_s4 svg {
  position: absolute;
  left: -30%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 280px;
  color: #df0022;
  z-index: -1;
}

/* SEÇÃO 5 */

.secao5 {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 0 50px 50px 50px;
}

.secao5 a {
  max-width: 300px !important;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.s2_descr {
  position: relative;
  display: inline-block;
}
.s2_descr::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--verde);
  transition: width 0.5s ease;
}

.secao5 a:hover .s2_descr::after {
  width: 100%;
}

.img-container {
  width: 300px;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.secao5 a img {
  transition: transform 0.6s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.secao5 a:hover img {
  transform: scale(1.1);
}

.secao5 a p:last-child {
  color: var(--verde);
  font-size: 30px;
  margin-top: 0px;
}

.secao5 a p {
  margin-top: 10px;
  font-size: 20px;
  position: relative;
  display: inline-block;
}

/* SEÇÃO 6 */

.secao6 {
  padding: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.secao6 p {
  color: var(--vermelho);
  font-size: 45px;
  width: 700px;
  text-align: center;
  position: relative;
  line-height: 1.2;
}

.secao6 .span_receitas {
  position: relative;
  display: inline-block;
}

.svg_receitas {
  position: absolute;
  top: 90%;
  height: 20%;
  width: 100%;
  pointer-events: none;
  z-index: -1;
  filter: drop-shadow(0 0 1px #007943ad);
}

.secao6 a {
  background-color: var(--vermelho);
  color: #fff;
  padding: 18px 40px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  transition: 0.2s;
}

.secao6 a:hover {
  background-color: #eb2f29;
}

/* RESPONSIVIDADE SEÇÃO 2: */
@media (max-width: 1100px) {
  .secao2 {
    flex-direction: column-reverse;
    margin: 0;
    align-items: center;
    gap: 10vh;
  }
  .div_textos_s2 {
    width: 80%;
  }
  .secao2 img {
    width: 80%;
    height: 80%;
  }
}
@media (max-width: 580px) {
  .titulo_s2 {
    font-size: 26px;
  }
}
@media (max-width: 530px) {
  .div_textos_s2 {
    font-size: 15px;
    gap: 25px;
  }
}
@media (max-width: 390px) {
  .titulo_s2 {
    font-size: 24px;
  }
}
@media (max-width: 360px) {
  .titulo_s2 {
    font-size: 20px;
  }
}

/* RESPONSIVIDADE SEÇÃO 3: */
@media (max-width: 1480px) {
  .titulo_div1_s3,
  .titulo_div2_s3,
  .titulo_div3_s3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .div1_s3,
  .div2_s3,
  .div3_s3 {
    height: auto;
    width: 40%;
    padding: 35px;
  }
  .secao3 h1 {
    font-size: 40px;
    width: 80%;
  }
}
@media (max-width: 1000px) {
  .div_s3 {
    flex-direction: column;
    align-items: center;
  }

  .div1_s3,
  .div2_s3,
  .div3_s3 {
    width: 90vw;
    height: 200px;
  }
  .secao3 h1 {
    width: 80vw;
  }
}
@media (max-width: 520px) {
  .div1_s3,
  .div2_s3,
  .div3_s3 {
    padding-bottom: 20px;
    height: auto;
  }
  .secao3 h1 {
    font-size: 25px;
    width: 90vw;
    font-family: "Montserrat", "Ubuntu";
  }
}

/* RESPONSIVIDADE SEÇÃO 4: */
@media (max-width: 750px) {
  .secao4 {
    height: 300px;
  }
  .secao4 p {
    font-size: 30px;
  }
  .span_s4 svg {
    font-size: 200px;
  }
}
@media (max-width: 545px) {
  .secao4 {
    height: 200px;
  }
  .secao4 p {
    font-size: 25px;
    text-align: center;
  }
  .span_s4 svg {
    font-size: 170px;
  }
}

/* RESPONSIVIDADE SEÇÃO 5: */
@media (max-width: 850px) {
  .secao5 {
    flex-direction: column;
    align-items: center;
  }
}

/* RESPONSIVIDADE SEÇÃO 6: */
@media (max-width: 750px) {
  .secao6 p {
    font-size: 30px;
    width: 90%;
    text-align: center;
    position: relative;
    line-height: 1.2;
  }
}
