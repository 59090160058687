@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

/* SEÇÃO 1 */

@keyframes zoomReverso {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.main_produtos {
  background-color: #fff;
}

.p_s1 {
  background-image: url("../../assets/img/p_s1_img.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 8%;
  position: relative;
  overflow: hidden;
  animation: zoomReverso 1s ease-out forwards;
  width: 100%;
}

.p_s1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-position: center;
  background-repeat: inherit;
  background-size: cover;
  z-index: -1;
}

.p_s1 h1 {
  font-size: 10vh;
  font-weight: 700;
}

.p_s1_descr {
  font-size: 5vh;
}

.p_div_links {
  display: flex;
  justify-content: space-around;
  margin-right: 5%;
  margin-bottom: 20px;
  font-size: 22px;
}

.p_div_links a {
  border-bottom: 2px solid #fff;
  font-weight: 500;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.h1_prod,
.p_s1_descr,
.prod_h1_s2 {
  animation: fadeInUp 0.8s ease-out;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* SEÇÃO 2 */

.prod_s2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 10vw;
}

/* Mantém 4 produtos em telas maiores */
@media (min-width: 1700px) {
  .prod_s2 {
    margin: 0 15vw;
  }
}

.container_produtos {
  display: flex;
  flex-direction: column;
  padding: 30px;
  cursor: pointer;
}

.container_produtos img {
  width: 220px !important;
  height: 280px;
}

.container_produtos p {
  color: #3d3d3d;
  margin-top: 10px;
  font-size: 16px;
  max-width: 220px;
  white-space: nowrap; 
}


.prod_h1_s2 {
  font-size: 6vh;
  color: var(--verde);
  text-align: center;
}

.prod_s2:last-child {
  margin-bottom: 4%;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.leia_mais {
  color: #fff !important;
  background-color: var(--verde);
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-top: -40px !important;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.4s ease, transform 0.4s ease;
  font-size: 15px !important;
  font-weight: 500;
}

.container_produtos:hover .leia_mais {
  opacity: 1;
  transform: translateY(0);
}

html {
  scroll-behavior: smooth;
}

.p_id {
  padding-top: 5%;
}

.p_div_links_mobile {
  display: none;
}

/* RESPONSIVIDADE SEÇÃO 1: */
@media (max-width: 820px) {
  .p_s1 h1 {
    font-size: 40px;
    text-align: center;
    padding-right: 10%;
  }
  .p_s1_descr {
    font-size: 25px;
    text-align: center;
    padding-right: 10%;
  }
  .container_produtos p {
    white-space: normal; 
  }
}
@media (max-width: 600px) {
  .p_s1 h1 {
    margin-top: 10%;
  }
  .p_div_links {
    display: none;
  }
  .p_div_links_mobile {
    display: flex;
    margin-right: 0;
    margin-bottom: 30px;
    font-size: 22px;
    justify-content: space-around;
  }
  .p_div_links_mobile div {
    display: flex;
    flex-direction: column;
  }
  .p_div_links_mobile a {
    border-bottom: 2px solid #fff;
    font-weight: 500;
    text-align: center;
    margin: 10px 0;
  }
  .p_s1 {
    background-image: url("../../assets/img/p_s1_img_mobile.png");
  }
}


/* RESPONSIVIDADE SEÇÃO DOS PRODUTOS: */
@media (max-width: 1100px) {
  .prod_s2 {
    margin: 0 5vw;
  }
  .leia_mais {
    opacity: 1;
    transform: translateY(10px);
  }
}
@media (max-width: 630px) {
  .container_produtos img {
    width: 150px;
    height: 200px;
  }
  .container_produtos {
    padding: 5px;
    width: 40%;
    align-items: center;
  }
  .prod_h1_s2 {
    font-size: 30px;
    margin-bottom: 15px;
  }
}
@media (max-width: 460px) {
  .container_produtos img {
    width: 130px;
    height: 180px;
  }
  .container_produtos {
    padding: 5px;
    width: 40%;
    align-items: center;
  }
}
@media (max-width: 400px) {
  .container_produtos p {
    font-size: 15px;
  }
}