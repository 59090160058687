.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0; /* Inicialmente invisível */
  animation: fadeIn 0.5s forwards; /* Animação de fade-in */
}

@keyframes fadeIn {
  to {
    opacity: 1; /* Torna o elemento visível */
  }
}

.popup_content {
  position: relative;
  border-radius: 8px;
  transform: scale(0.8); /* Inicialmente menor */
  animation: zoomIn 0.5s forwards; /* Animação de zoom-in */
}

@keyframes zoomIn {
  to {
    transform: scale(1); /* Tamanho normal */
  }
}

.close_button {
  position: absolute;
  top: 75px;
  right: -10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #19a556;
  box-shadow: inset 0 0 5px 10px #17944d;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
}

.popup_content img {
  width: 100%;
  height: auto;
}


@media (max-width: 1050px) {
  .popup_content {
    margin: 0 20px;
  }
}
@media (max-width: 975px) {
  .close_button {
    top: 68px;
  }
}
@media (max-width: 885px) {
  .close_button {
    top: 58px;
  }
}
@media (max-width: 770px) {
  .close_button {
    top: 50px;
  }
}
@media (max-width: 680px) {
  .close_button {
    top: 40px;
  }
}
@media (max-width: 570px) {
  .close_button {
    top: 30px;
  }
}
@media (max-width: 480px) {
  .close_button {
    top: 20px;
  }
}
@media (max-width: 400px) {
  .close_button {
    top: 15px;
  }
}