@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

main {
  background-color: #fffbf4;
}

.page_404 {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   color: #3d3d3d;
}

.page_404 h1 {
   font-size: 40vh;
   font-weight: 500;
   margin-top: 3%;
}

.page_404 p {
   margin-top: -5%;
   margin-bottom: 1%;
   font-size: 8vh;
   font-weight: 500;
}

.page_404 a {
   font-size: 2.5vh;
   font-weight: 500;
   background-color: #00823d;
   color: #fff;
   padding: 10px 30px 10px 40px;
   border-radius: 5px;
   margin-bottom: 10%;
}

.page_404 span {
   margin-left: 10px;
}