@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

.main_produto {
  background-color: #fff;
}

.container_produto {
  display: flex;
  justify-content: center;
  padding: 10%;
}

.img_container {
  position: relative;
  width: 30%;
}

.container_produto img {
  width: 100%;
  display: block; /* Garante que a imagem se comporte corretamente */
}

.zoom {
  position: absolute;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 2px solid var(--verde);
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 280%;
  display: none;
}

.img_container:hover .zoom {
  display: block;
}

.descricao_produto {
  margin: 0 10%;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: #3d3d3d;
}

.descricao_produto h1 {
  font-size: 30px;
  font-weight: 500;
}

.descricao_produto ul {
  margin-left: 20px;
}

.descricao_produto ul li {
  margin-bottom: 2px;
}

.li_caixa {
  margin-left: 30px;
}

@media (max-width: 950px) {
  .container_produto {
    flex-direction: column;
    align-items: center;
    padding: 0;
    padding-top: 100px;
    margin-bottom: 50px;
  }

  .img_container {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .container_produto img {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .img_container:hover .zoom {
    display: none;
  }
}

@media (max-width: 680px) {
  
  .img_container {
    width: 80%; 

  }
  .container_produto img {
    width: 80%; 
    text-align: center;
  }
}

@media (max-width: 500px) {
  .descricao_produto h1 {
    font-size: 25px;
  }
}

@media (max-width: 440px) {
  .descricao_produto h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

@media (max-width: 360px) {
  .descricao_produto h1 {
    font-size: 18px;
  }
}
