@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

.main_qs {
  background-color: #fffbf4;
}

/* SEÇÃO 1 */

@keyframes zoomReverso {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.tc_s1 {
  background-image: url("../../assets/img/background_trabcon.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding-left: 10%;
  position: relative;
  overflow: hidden;
  animation: zoomReverso 1s ease-out forwards;
  width: 100%;
}

.tc_s1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-position: center;
  background-repeat: inherit;
  background-size: cover;
  z-index: -1;
}

.tc_s1 h1 {
  font-size: 9vh;
  font-weight: 500;
}

.tc_s1 p {
  font-size: 3.5vh;
  font-weight: 400;
  width: 55%;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.h1_trab_con,
.p_trab_con {
  animation: fadeInUp 0.8s ease-out;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* SEÇÃO 2 */

.tc_s2 {
  display: flex;
  justify-content: center;
  margin: 6% auto;
  background-color: #fff;
  box-shadow: 0 0 1px 1px #f5f5f5;
  padding: 50px;
  border-radius: 5px;
  width: 40%;
}

.container_inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.tc_s2 div label {
  color: #3d3d3d;
  font-weight: 200;
}

.container_inputs input {
  border-bottom: 1px solid var(--verde);
}

.container_inputs input:hover,
.container_inputs input:focus {
  border-bottom: 1px solid var(--vermelho);
  outline: none;
}

.container_curriculo {
  display: flex;
  flex-direction: column;
}

.container_curriculo input {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}

.container_checkbox {
  padding: 15px;
  font-size: 17px;
}

.container_checkbox input {
  margin-right: 5px;
}

.container_checkbox a {
  color: var(--verde);
}

.botao_tc {
  background-color: #00823d;
  color: #fff;
  border-radius: 5px;
  padding: 12px 50px;
  font-size: 20px;
  font-weight: 500;
  transition: 0.2s;
}

.botao_tc:hover {
  background-color: #26955a;
}

/* RESPONSIVIDADE SEÇÃO 1: */
@media (max-width: 930px) {
  .tc_s1 {
    align-items: center;
    padding-left: 0;
  }
  .tc_s1 h1 {
    font-size: 45px;
  }
  .tc_s1 p {
    font-size: 20px;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .tc_s1 h1 {
    font-size: 35px;
    margin: 20px;
    text-align: center;
  }
}
@media (max-width: 460px) {
  .tc_s1 p {
    font-size: 18px;
  }
}

/* RESPONSIVIDADE SEÇÃO 2: */
@media (max-width: 570px) {
  .tc_s2 {
    width: 80%;
  }
  .container_checkbox {
    font-size: 15px;
  }
  .botao_tc {
    font-size: 18px;
  }
}
@media (max-width: 415px) {
  .tc_s2 {
    width: 90%;
  }
}
@media (max-width: 350px) {
  .tc_s2 {
    width: 100%;
  }
}
