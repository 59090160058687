@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

.main_qs {
  background-color: #fffbf4;
}

.section_priv {
  display: flex;
  flex-direction: column;
  margin: 0 10%;
  color: #3d3d3d;
  font-weight: 200;
  gap: 20px;
}

.section_priv {
  margin-bottom: 5%;
}

.section_priv h1 {
  font-size: 55px;
  color: var(--verde);
  font-weight: 500;
  margin-top: 10%;
  text-align: center;
}

.section_priv h3 {
  font-weight: 700;
  font-size: 18px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.h1_politica_priv {
  animation: fadeInUp 0.8s ease-out;
}

@media (max-width: 950px) {
  .section_priv h1 {
    font-size: 40px;
    margin-top: 20%;
  }
}
@media (max-width: 550px) {
  .section_priv h1 {
    font-size: 30px;
    margin-top: 30%;
  }
}
@media (max-width: 460px) {
  .section_priv h1 {
    font-size: 25px;
    margin-top: 30%;
  }
}
@media (max-width: 330px) {
  .section_priv h1 {
    font-size: 20px;
    margin-top: 40%;
  }
}