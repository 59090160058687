@import url('https://fonts.googleapis.com/css2?family=Anton+SC&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.bodyAgendar .modalOverlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.7) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    z-index: 1000 !important;
}
  
.bodyAgendar .modalContent {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    background: #A4CF8B !important;
    padding: 20px !important;
    border-radius: 15px !important;
    max-width: 400px !important;
    width: 92% !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    z-index: 1001 !important;
}

.bodyAgendar .paraginicial{
    margin-top: 5px !important;
    font-size: 12.5px !important;
    margin-bottom: 6px !important;
}

.bodyAgendar .parag{
    margin-top: 4px !important;
    font-size: 14px !important;
}

.bodyAgendar .modalActions {
    margin-top: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.bodyAgendar .tituloModal {
    color: rgb(26, 39, 65) !important;
    font-family: "DM Sans", sans-serif !important;
    font-size: 21px !important;
    font-weight: bold;
    margin-left: 3% !important;
}
  
.bodyAgendar .botaoConfirmar, .bodyAgendar .botaoCancelar, .bodyAgendar .botaoFechar {
    margin: 5px !important;
    padding: 10px 20px !important;
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer !important;
}
  
.bodyAgendar .botaoConfirmar {
    background-color: #369c3a !important;
    color: white !important;
}
  
.bodyAgendar .botaoCancelar {
   background-color:  rgb(26, 39, 65) !important;
   color: #ffffff;
}
  
.bodyAgendar .botaoFechar {
    background-color: #e93529 !important;
    color: white !important;
}

.bodyAgendar head {
    display: none;
}

.bodyAgendar header {
    display: block;
    unicode-bidi: isolate;
}

.bodyAgendar img {
    overflow-clip-margin: content-box;
    overflow: clip;
}

.bodyAgendar form {
    unicode-bidi: isolate;
}

.bodyAgendar div {
    display: block;
    unicode-bidi: isolate;
}

.bodyAgendar h2 {
    font-weight: bold;
}

.bodyAgendar h1 {
    display: block;
    font-weight: bold;
}

.bodyAgendar input {
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: field;
    margin: 0em;
    padding: 1px 0px;
    border-width: 2px;
    border-style: inset;
    border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
    padding-block: 1px;
    padding-inline: 2px;
}

.bodyAgendar select {
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    box-sizing: border-box;
    align-items: center;
    -webkit-rtl-ordering: logical;
    background-color: field;
    cursor: default;
    margin: 0em;
    white-space: pre;
    border-width: 1px;
    border-style: solid;
    border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
    border-radius: 0px;
}

.bodyAgendar select:not(:-internal-list-box) {
    overflow: visible !important;
}

.bodyAgendar option:disabled {
    color: light-dark(graytext, rgb(170, 170, 170));
}

.bodyAgendar footer {
    display: block;
    unicode-bidi: isolate;
}

* {
    margin: 0;
    padding: 0;
}

.bodyAgendar {
    background-image: url("../../assets/img/brocolis 2.jpg") !important;
    background-size: cover !important; 
    background-position: center !important;
    height: 100vh;
}

.bodyAgendar input[type="number"]::-webkit-outer-spin-button, 
.bodyAgendar input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.cabecalhoPrincipal {
    background-color: rgb(164, 207, 139) !important;
    height: 4.5em !important;
    width: 100% !important;
    position: fixed !important;
    top: 0 !important;
}

.imagemLogo {
    padding-top: 0.5% !important;
    padding-left: 0.3% !important;
    margin-left: 3% !important;
}

.formularioPrincipal {
    display: flex !important;
    height: 32em !important; 
    width: 90% !important;
}

.divGeral {
    background-color: rgb(164, 207, 139) !important;
    height: 33.5em !important; 
    width: 40% !important;
    margin-top: 7% !important;
    margin-left: 35.5% !important;
    border-radius: 30px !important;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4) !important;
}

.dataEntrega, 
.divProdutos, 
.divCPF {
    margin-bottom: 6% !important;
}

.divTitulo {
    display: flex !important;
    flex-direction: column !important; 
    align-items: center !important; 
    justify-content: space-between !important; 
    text-align: center !important;
    margin-top: 15px !important;
}

.tituloPrincipal {
    display: flex !important;
    width: 100% !important;
    align-items: center !important;    
    justify-content: center !important;
}

.tituloGeral {
    margin-top: 7px !important;
    font-family: "DM Sans", sans-serif !important;
    font-size: 34px !important;
    width: 110% !important;
    text-align: center !important;
    color: rgb(26, 39, 65) !important;
}

.imagemTrator {
    margin-left: 2px !important;
    margin-right: 5.5% !important;
    width: 45px !important;
    display: none !important;
    height: 45px !important;
    margin-top: 5px !important;
}

.subdivisao {
    width: 70% !important;
    margin-left: 15% !important;
    margin-top: 5% !important;
    text-align: center !important;
}

.subtitulo {
    color: rgb(26, 39, 65) !important;
    font-family: "DM Sans", sans-serif !important;
    font-size: 20px !important;
    margin-bottom: -3.0% !important;
}

.tituloProdutos,
.tituloEntrega,
.tituloCpf,
.quantidadeEntrega {
    color: rgb(26, 39, 65) !important;
    font-family: "DM Sans", sans-serif !important;
    font-size: 18px !important;
    text-align: left !important;
    margin-left: 3% !important;
}

.seletorProdutos {
    width: 100% !important;
    height: 2.0em !important;
    border-radius: 8px !important;
    font-family: "DM Sans", sans-serif !important;
    text-align: center !important;
    border: none !important;
}

.inputCpf, 
.inputQuantidade, 
.inputData {
    width: 100% !important;
    height: 1.8em !important;
    border-radius: 8px !important;
    text-align: center !important;
    font-family: "DM Sans", sans-serif !important;
    border: none !important;
}

.inputCpf::placeholder {
    text-align: center !important;
    font-family: "DM Sans", sans-serif !important;
}

.botaoEnviar {
    width: 60% !important;
    height: 3em !important;
    border-radius: 8px !important;
    margin-top: 30px !important;
    font-family: "DM Sans", sans-serif !important;
    background-color: rgb(26, 39, 65) !important;
    color: #EDEDEDFF !important;
    margin-left: 10px !important;
}

.bodyAgendar .aviso {
    margin-top: 2%;
    color: #9f0000 !important;
    font-family: "DM Sans", sans-serif !important;
    font-weight: bold;
    font-size: 15px !important;
    text-align: center !important;
}


@media (min-width:200px) and (max-width:270px){
    .divGeral {
        height: 40em !important;
    }
}
@media (max-width: 450px) {
    .divGeral {
        margin-left: 2.5% !important;
        width: 100% !important;
        margin-top: 40% !important;
        height: 31em !important;
    }
    .bodyAgendar .aviso{
        font-size: 11px !important;
    }
    .tituloGeral {
        font-size: 20px !important;
        text-align: center !important;
        margin-top: 8px !important;
    }

    .subtitulo {
        font-size: 15px !important;
        margin-top: 2% !important;
        margin-bottom: -3.0% !important;
    }

    .divTitulo {
        text-align: center !important;
    }

    .formularioPrincipal {
        width: 95% !important;
    }

    .imagemTrator {
        height: 35px !important;
        display: none;
    }
    
    .seletorProdutos,
    .tituloEntrega,
    .tituloCpf,
    .quantidadeEntrega {
        font-size: 15px !important;
    }
}

@media (min-width: 451px) and (max-width: 800px) {
    .divGeral {
        margin-left: 2.5% !important;
        width: 100% !important;
        margin-top: 20% !important;
        height: 36em !important;
    }

    .bodyAgendar .aviso{
        font-size: 11px !important;
    }

    .tituloGeral {
        font-size: 20px !important;
        text-align: center !important;
        margin-top: 8px !important;
    }

    .formularioPrincipal {
        width: 95% !important;
    }

    .divTitulo {
        width: 90% !important;
        text-align: center !important;
    }
    
    .imagemTrator {
        height: 35px !important;
        margin-left: 1px !important;
        margin-right: 12% !important;
        display: none ;
    }
    
    .seletorProdutos,
    .tituloEntrega,
    .tituloCpf,
    .quantidadeEntrega {
        font-size: 15px !important;
    }
}

@media (min-width: 801px) and (max-width: 1250px) {


    .formularioPrincipal{
        width: 100% !important;
        margin-left: -10% !important;
    } 
    .divGeral {
        margin-top: 15% !important;
        width: 100% !important;
        height: 35em !important;
    }
    .tituloGeral{
        font-size: 18px;
    }

  }

@media (min-width: 1565px) {
    .divGeral {
        margin-top: 6% !important;
        height: 42em !important;
        width: 40em !important;
        margin-left: 37% !important;
    }

    .tituloGeral {
        margin-top: 11px !important;
        font-size: 38px !important;
        text-align: center !important;
    }
    
    .formularioPrincipal {
        height: 45em !important;
    }
    
    .imagemTrator {
        width: 60px !important;
        margin-right: 65px !important;
        margin-top: 15px !important;
        margin-left: -5% !important;
    }
    
    /* .seletorProdutos,
    .tituloEntrega,
    .tituloCpf,
    .quantidadeEntrega {
        font-size: 23px !important;
    } */
    
    .inputCpf, 
    .inputQuantidade, 
    .inputData, 
    .seletorProdutos {
        height: 3em !important;
        border: none !important;
        width: 450px !important;
    }
    
    .botaoEnviar {
        width: 200px !important;
        height: 3.5em !important;
        margin-left: 2% !important;
        font-size: 18px !important;
    }
    
    .subdivisao {
        margin-left: 90px !important;
    }
}


@media (min-width: 0px) and (max-width:350px){
     
    .bodyAgendar .modalActions{
    display: inline-block !important;
    }

    .bodyAgendar .botaoConfirmar, .bodyAgendar .botaoCancelar, .bodyAgendar .botaoFechar{
        width: 92% !important;
        font-size: 13px !important;
    } 

    .bodyAgendar .tituloModal{
        font-size: 16px !important;
    }

    .divGeral {
        height: 33em !important;
    }
}