.cabecalho {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0;
  background-color: #fff;
  position: fixed;
  width: 100%;
  box-shadow: 0 0 1px 1px #ccc;
  z-index: 10;
  transition: padding 0.3s ease;
}

.logo {
  width: 100px;
  transition: width 0.3s ease;
  cursor: pointer;
}

.logoScrolled {
  width: 80px;
}

.cabecalhoScrolled {
  padding: 10px 0;
}

.div_cabecalho {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 20px;
}

.div_cabecalho a {
  color: var(--verde);
  transition: 0.2s;
  position: relative;
}

.botao_contato {
  background-color: var(--vermelho);
  color: #fff !important;
  padding: 5px 15px;
  border-radius: 6px;
  box-shadow: 0 0 1px 1px var(--vermelho);
  transition: 0.3s;
  margin: 0 10px;
}

.botao_contato:hover {
  background-color: #d4221c;
  transform: scale(1.08);
}

.div_botoes_cabecalho {
  display: flex;
  gap: 20px;
  align-items: end;
}

.div_botoes_cabecalho a {
  width: 20px;
}

.div_botoes_cabecalho_mobile a {
  width: 20px;
}

.div_linguagens {
  display: flex;
  gap: 10px;
  align-items: end;
}

.link_idioma {
  width: 22px;
  transition: 0.2s;
}

.link_idioma:hover {
  transform: scale(1.1);
}

.links_cab {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.links_cab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1.4px;
  background-color: #df0022;
  transition: width 0.3s ease-in-out;
}

.links_cab:hover::after,
.links_cab.active::after {
  width: 100%;
}

.div_botoes_cabecalho a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding-bottom: 5px;
}

.div_botoes_cabecalho a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px !important;
  background-color: #df0022;
  transition: width 0.3s ease-in-out;
}

.div_botoes_cabecalho a:hover::after {
  width: 100%;
}



.icone_cabecalho_celular {
  display: none;
}

.div_cabecalho_celular {
  display: none;
}

.hidden {
  display: none;
}


@media (max-width: 1000px) {
  .icone_cabecalho_celular {
    display: block;
    padding-right: 20px;
    cursor: pointer;
  }

  .cabecalho {
    justify-content: space-between;
  }

  .logo {
    padding-left: 10px;
  }

  .div_cabecalho {
    display: none;
  }

  .div_cabecalho_celular {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 4;
    background-color: #fff;
    padding: 40px 20px 20px 20px;
    color: var(--verde);
    font-size: 22px;
    font-weight: 500;
    border-radius: 10px;
    height: 100%;
  }
  .div_cabecalho_celular img {
    margin-bottom: 3px;
  }

  .botao_contato {
    background-color: inherit;
    color: var(--verde) !important;
    padding: 0;
    transition: 0.3s;
    margin: 0;
    box-shadow: none;
  }

  .botao_contato::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1.4px;
    background-color: #df0022;
    transition: width 0.3s ease-in-out;
  }

  .botao_contato:hover::after,
  .botao_contato.active::after {
    width: 100%;
  }

  .botao_contato:hover {
    background-color: inherit;
    transform: scale(1);
  }
  .div_botoes_cabecalho_mobile {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    align-items: end;
  }
}
