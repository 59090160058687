@import url("https://fonts.googleapis.com/css2?family=Brygada+1918:ital,wght@0,400..700;1,400..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

.main_linktree {
  background-image: url("../../assets/img/bg_linktree.png");
  background-size: cover;
  background-position: center;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  padding: 1dvh 0;
}

.logo_lt {
  width: 150px;
  margin-bottom: 10px;
}

.div_links_lt {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 80%;
}

.div_links_lt a {
  border: 1px solid #fff;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

.div_descr_lt h1 {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.div_descr_lt p {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 560px) {
  .div_links_lt {
    width: 500px;
  }
}
