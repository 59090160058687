@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");

main {
  background-color: #f3e6cc;
  z-index: 1;
}

.header {
  background-color: #0a4635;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header img {
  width: 12vw;
}

.banner_form {
  background-image: url(../../assets/img/banner.png);
  height: 800px;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  overflow: visible;
}

.div_banner {
  color: #fff;
  width: 40%;
  margin-right: 50%;
}

.div_banner h1 {
  font-size: 3.5vw;
  text-align: center;
  line-height: 115%;
}

.div_banner p {
  font-size: 3vw;
  text-align: center;
  margin-top: 60px;
  font-weight: 500;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #df231d;
  box-shadow: inset 0 0 50px 50px #c9201a;
  border: 2px solid #df231d;
  padding: 5% 5% 4% 5%;
  border-radius: 15px;
  transition: 0.3s;
  z-index: 100;
  position: absolute;
  bottom: -10vh;
  left: 50%;
}

.form:hover {
  background-color: #c9201a;
  border: 2px solid #c9201a;
}

.form label {
  color: #fff;
  font-size: 1.8vw;
}

.form div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form input,
.form select {
  width: 30vw;
  height: 4.5vh;
  border-radius: 30px;
  padding: 0 10px;
}

.btn_form {
  background-color: #000;
  color: #fff;
  margin: 8% auto 0 auto;
  font-size: 2vw;
  padding: 1% 10%;
  border-radius: 20px;
}

.section_produtos img {
  width: 90%;
  margin-bottom: -10%;
}

.section_produtos {
  display: flex;
  justify-content: center;
  margin-top: 12%;
}

.section3 {
  background-image: url(../../assets/img/FundoVermelho.png);
  height: 90vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.img_s3 {
  position: absolute;
  top: -20%;
  right: 0;
  width: 55%;
}

.div_s3 {
  color: #fff;
  font-size: 2.1vw;
  width: 45%;
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.a_s3 {
  font-size: 2.5vw;
  background-color: #000;
  text-align: center;
  border-radius: 15px;
  font-weight: 500;
  padding: 5px;
}

.section4 {
  background-image: url(../../assets/img/background_s4.png);
  height: 150vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: center;
}

.div_s4 {
  display: flex;
  flex-direction: column;
  gap: 15vh;
}

.div2_s4 {
  display: flex;
  justify-content: center;
  gap: 5vw;
}

.container_s4 {
  font-size: 2vw;
  width: 35%;
}

.container_s4 p {
  background-color: #df231d;
  padding: 5%;
  border-radius: 30px;
  line-height: 120%;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_s4 h3 {
  background-color: #fff;
  color: #df231d;
  font-family: "Bree Serif", sans-serif;
  font-weight: 900;
  font-size: 2.5vw;
  width: fit-content;
  padding: 5px 10% 5% 5%;
  border-radius: 20px;
  margin-bottom: -20px;
}

.a_s4 {
  background-color: #0a4635;
  font-size: 2.3vw;
  font-weight: 500;
  margin-top: 8%;
  padding: 5px 3%;
  border-radius: 15px;
}

.section5 {
  background-image: url(../../assets/img/background_s5.png);
  height: 120vh;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.broc1 {
  position: absolute;
  left: 0;
  top: -40%;
}

.broc2 {
  position: absolute;
  right: 0;
  bottom: -40%;
}

.section5 div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  margin-bottom: 8%;
}

.img_s5_none,
.img_s5,
.broc1,
.broc2 {
  width: 25%;
}

.section5 p {
  font-size: 2.1vw;
  width: 50%;
  text-align: justify;
  line-height: 130%;
}

.section5 h2 {
  font-family: "Bree Serif", sans-serif;
  font-size: 4vw;
  margin: 8% 0 2% 25%;
  text-align: center;
}

.section6 {
  background-image: url(../../assets/img/background_s6.png);
  height: 120vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10vh;
}

.section6 h2 {
  font-size: 3.5vw;
  font-family: "Libre Baskerville", sans-serif;
}

.section6 h2 span {
  font-family: "Libre Baskerville", sans-serif;
  color: #df231d;
  font-weight: 900;
}

.section6 p {
  font-family: "Montserrat", sans-serif;
  font-size: 2.5vw;
  width: 80%;
  text-align: center;
}

.a_s6 {
  color: #fff;
  background-color: #df231d;
  font-size: 2.3vw;
  width: 40%;
  text-align: center;
  padding: 1% 2%;
  font-weight: 500;
  border-radius: 15px;
  line-height: 120%;
}

.section7 {
  position: relative;
}

.cenoura {
  position: absolute;
  left: 0;
  top: -40%;
  width: 500px;
}

.footer {
  background-color: #0a4635;
  height: 30vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo_branca {
  width: 12%;
}

.footer div {
  display: flex;
  gap: 20%;
}

.footer a {
  width: 6.5vw;
}

.p_mobile {
  display: none;
}

.img_s5_none {
  display: none;
}

@media (min-width: 1600px) {
  .form {
    bottom: -20vh;
    padding: 2% 5% 2% 5%;
  }
}

@media (max-width: 1280px) {
  .img_s5 {
    display: none;
  }
  .img_s5_none {
    display: block;
    margin: 0 auto;
  }
  .section5 h2 {
    font-size: 45px;
    margin: 2%;
  }
  .section5 p {
    font-size: 25px;
    width: 80%;
  }
  .img_s5_none,
  .img_s5 .broc1,
  .broc2 {
    width: 250px;
  }
  .logo_branca {
    width: 150px;
  }
  .footer div {
    gap: 50px;
  }
  .footer a {
    width: 50px;
  }
  .broc1 {
    top: -20%;
  }
  .broc2 {
    bottom: -20%;
  }
  .cenoura {
    display: none;
  }
}

@media (max-width: 980px) {
  .header img {
    width: 150px;
  }
  .form {
    bottom: -15vh;
    left: 22%;
  }
  .form input,
  .form select {
    width: 400px;
  }
  .banner_form {
    align-items: start;
    height: 900px;
  }
  .div_banner {
    width: 60%;
    margin-right: 0%;
    margin-top: 5%;
  }
  .div_banner h1 {
    font-size: 5vw;
  }
  .p_desktop {
    display: none;
  }
  .p_mobile {
    display: block;
  }
  .form label {
    font-size: 20px;
  }
  .div_banner h1 {
    font-size: 45px;
  }
  .div_banner p {
    font-size: 25px;
    margin-top: 15px;
  }
  .btn_form {
    font-size: 20px;
  }
  .section_produtos img {
    z-index: 100;
  }
  .img_s3 {
    display: none;
  }
  .div_s3 {
    font-size: 22px;
    width: 60%;
    margin-left: 0%;
  }
  .section3 {
    height: 500px;
    justify-content: center;
    text-align: center;
  }
  .a_s3 {
    font-size: 18px;
  }
  .container_s4 p {
    height: 170px;
  }
  .section6 {
    height: 600px;
    gap: 40px;
  }
  .section6 h2 {
    font-size: 32px;
  }
  .section6 p {
    font-size: 26px;
  }
  .a_s6 {
    font-size: 22px;
    width: 70%;
  }

  .banner_form {
    background-image: url(../../assets/img/banner_mobile.png);
    background-position: 50%;
  }
}

@media (max-width: 800px) {
  .div_banner {
    width: 70%;
  }
  .form {
    left: 18%;
  }
  .container_s4 {
    font-size: 18px;
    width: 40%;
  }
  .container_s4 h3 {
    margin-bottom: -10px;
    font-size: 20px;
  }
}

@media (max-width: 700px) {
  .broc1 {
    top: -20%;
    width: 200px;
  }
  .broc2 {
    bottom: -20%;
    width: 200px;
  }
}

@media (max-width: 650px) {
  .form {
    left: 10%;
    bottom: -5vh;
  }
  .div_banner h1 {
    font-size: 35px;
  }
  .div_banner p {
    font-size: 18px;
    margin-top: 15px;
  }
  .container_s4 {
    font-size: 15px;
  }
  .container_s4 h3 {
    font-size: 18px;
  }
  .container_s4 p {
    height: 130px;
  }
  .section4 {
    height: 600px;
  }
  .a_s4 {
    font-size: 20px;
  }
  .section5 h2 {
    font-size: 30px;
  }
  .section5 p {
    font-size: 22px;
  }
  .section5 {
    height: 700px;
  }
  .section6 h2 {
    font-size: 26px;
    text-align: center;
  }
  .section6 p {
    font-size: 20px;
  }
  .a_s6 {
    font-size: 16px;
  }
  .broc1 {
    top: -15%;
    width: 150px;
  }
  .broc2 {
    bottom: -15%;
    width: 150px;
  }
}

@media (max-width: 575px) {
  .div_banner {
    width: 80%;
  }
  .div_s3 {
    width: 80%;
  }
  .div2_s4 {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .section4 {
    height: 900px;
  }
  .div_s4 {
    gap: 20px;
  }
  .container_s4 {
    width: 70%;
  }
  .section5 p {
    font-size: 20px;
  }
  .section5 {
    height: 720px;
  }
  .broc1 {
    top: -5%;
  }
}

@media (max-width: 550px) {
  .banner_form {
    height: 700px;
  }
  .form {
    gap: 20px;
    bottom: -10vh;
    left: 80px;
  }

  .form label {
    font-size: 18px;
  }

  .form div {
    gap: 5px;
  }

  .form input,
  .form select {
    width: 300px;
    height: 25px;
  }
}

@media (max-width: 500px) {
  .form {
    left: 60px;
  }
  .div_banner h1 {
    font-size: 30px;
  }
  .div_s3 {
    font-size: 20px;
  }
  .a_s3 {
    font-size: 15px;
  }
  .container_s4 {
    font-size: 15px;
    width: 70%;
  }
  .a_s4 {
    font-size: 17px;
    margin: 20px;
    text-align: center;
  }
  .footer div {
    gap: 20px;
  }
  .footer a {
    width: 40px;
  }
  .broc1 {
    top: -10%;
    width: 130px;
  }
  .broc2 {
    width: 130px;
  }
}

@media (max-width: 470px) {
  .form {
    left: 50px;
  }
}

@media (max-width: 460px) {
  .section5 h2 {
    font-size: 25px;
  }
  .section5 p {
    font-size: 18px;
  }
  .section5 {
    height: 720px;
  }
}

@media (max-width: 450px) {
  .form {
    left: 45px;
  }
}

@media (max-width: 440px) {
  .div_banner h1 {
    font-size: 30px;
  }
  .form {
    left: 30px;
  }
}

@media (max-width: 420px) {
  .banner_form {
    height: 660px;
  }
  .form {
    gap: 10px;
    bottom: -8vh;
    left: 40px;
  }

  .form label {
    font-size: 15px;
  }

  .form div {
    gap: 5px;
  }

  .form input,
  .form select {
    width: 280px;
  }
  .broc1 {
    top: -10%;
    width: 100px;
  }
}

@media (max-width: 410px) {
  .form {
    left: 35px;
  }
}

@media (max-width: 400px) {
  .form {
    left: 30px;
  }
  .footer {
    height: 150px;
  }
}

@media (max-width: 390px) {
  .div_banner h1 {
    font-size: 25px;
  }
  .banner_form {
    height: 600px;
  }
  .form {
    left: 45px;
  }
  .form input,
  .form select {
    width: 250px;
  }
  .div_s3 {
    font-size: 17px;
  }
  .a_s3 {
    font-size: 12px;
  }
  .logo_branca {
    width: 100px;
  }
  .footer a {
    width: 30px;
  }
}

@media (max-width: 385px) {
  .form {
    left: 35px;
  }
}

@media (max-width: 375px) {
  .section5 p {
    font-size: 16px;
  }
  .form {
    left: 30px;
  }
}

@media (max-width: 360px) {
  .form {
    left: 30px;
  }
}

@media (max-width: 350px) {
  .banner_form {
    height: 650px;
  }
  .form input,
  .form select {
    width: 230px;
  }
  .a_s6 {
    font-size: 12px;
  }
}

@media (max-width: 345px) {
  .section5 {
    height: 810px;
  }
  .div_s3 {
    font-size: 16px;
  }
  .form {
    left: 25px;
  }
}

@media (max-width: 325px) {
  .banner_form {
    height: 580px;
  }
  .div_banner h1 {
    font-size: 20px;
  }
  .div_banner p {
    font-size: 15px;
  }
  .form {
    left: 20px;
  }
}
