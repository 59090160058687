@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

.main_ctt {
  background-color: #fff;
}

/* SEÇÃO 1 */
@keyframes zoomReverso {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.ctt_s1 {
  background-image: url("../../assets/img/ctt_bg_s1.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  display: flex;
  align-items: start;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  padding-left: 8%;
  position: relative;
  overflow: hidden;
  animation: zoomReverso 1s ease-out forwards;
}

.ctt_s1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-position: inherit;
  background-repeat: inherit;
  background-size: cover;
  z-index: -1;
}

.ctt_s1 h1 {
  font-size: 9vh;
  font-weight: 500;
}

.ctt_s1 p {
  font-size: 5vh;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.titulo_ctt {
  animation: fadeInUp 0.8s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.titulo_ctt {
  animation: fadeInUp 0.8s ease-out;
}

/* SEÇÃO 2 */

.ctt_s2 {
  display: flex;
  justify-content: center;
  gap: 10%;
}

.div_endereco {
  color: #00823d;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-weight: 200;
  margin-bottom: 10%;
  font-size: 17px;
}

.div_endereco div :nth-child(1) {
  display: flex;
}

.ctt_tel_email a {
  display: flex;
  gap: 10px;
  align-items: center;
}

.ctt_tel_email a:hover {
  color: var(--vermelho);
}

.div_ctt_endereco {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ctt_ind_escr {
  font-size: 4vh;
  font-weight: 400;
}

.rs_sp {
  color: #3d3d3d;
}

.ctt_endereco_maps:hover {
  color: #3d3d3d;
}

.ctt_form {
  display: flex;
  flex-direction: column;
  width: 30vw;
  gap: 20px;
}

.ctt_form div {
  display: flex;
  flex-direction: column;
}

.ctt_form label {
  font-size: 17px;
  font-weight: 200;
}

.ctt_form input,
.ctt_form textarea {
  font-size: 17px;
  font-weight: 200;
  border-bottom: 1px solid var(--verde);
  background-color: #fffbf4;
}

.ctt_form input:focus,
.ctt_form textarea:focus {
  transform: 0.2s;
  outline: none;
}

.ctt_form input:hover,
.ctt_form textarea:hover {
  border-bottom: 1px solid var(--vermelho);
}

.msg_contato {
  height: 100px;
}

.ctt_botao {
  font-size: 20px;
  background-color: #00823d;
  padding: 10px 40px;
  font-weight: 500;
  text-align: start;
  color: #fff;
  margin: 0 auto 0 0;
  border-radius: 5px;
  transition: 0.2s;
}

.ctt_botao:hover {
  background-color: #00823ddc;
}

/* RESPONSIVIDADE SEÇÃO 1: */
@media (max-width: 830px) {
  .ctt_s1 {
    align-items: center;
    padding-left: 0%;
  }
  .ctt_s1 h1 {
    font-size: 50px;
    text-align: center;
  }
  .ctt_s1 p {
    font-size: 25px;
  }
}
@media (max-width: 600px) {
  .ctt_s1 {
    background-image: url("../../assets/img/ctt_bg_s1_mobile.png");
  }
}
@media (max-width: 400px) {
  .ctt_s1 p {
    font-size: 20px;
    text-align: center;
  }
}

/* RESPONSIVIDADE SEÇÃO 2: */
@media (max-width: 900px) {
  .ctt_s2 {
    flex-direction: column;
    align-items: center;
    gap: 8%;
  }
  .div_endereco {
    width: 70%;
  }
  .ctt_form {
    width: 70vw;
  }
  .ctt_botao {
    margin-bottom: 20px;
  }
}