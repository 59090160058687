@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --verde: #007943;
  --vermelho: #df231d;
}

.main_qs {
  background-color: #faf6f0;
}

/* SEÇÃO 1 */

@keyframes zoomReverso {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.backgroundLavoura {
  background-image: url("../../assets/img/background_lavoura.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  animation: zoomReverso 1s ease-out forwards;
  width: 100%;
}

.backgroundLavoura::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-position: center;
  background-repeat: inherit;
  background-size: cover;
  z-index: -1;
}

.backgroundLavoura h1 {
  color: #fff;
  font-size: 60px;
  text-align: start;
  width: 70%;
  margin-left: 10%;
  font-weight: 500;
}

.svg_seta {
  background-color: var(--vermelho);
  padding: 15px;
  border-radius: 50%;
  margin-left: 10%;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.h1_animation_qs {
  animation: fadeInUp 0.8s ease-out;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* SEÇÃO 2 */

.qs_s2 {
  display: flex;
  justify-content: center;
  gap: 5%;
  margin-bottom: 8%;
}

.qs_s2 img {
  width: 35%;
  border-radius: 15px;
}

.qs_s2_div1 {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #3d3d3d;
  font-weight: 200;
  font-size: 2.5vh;
}

.qs_s2_titulo {
  color: var(--verde);
  font-size: 5vh;
}

.qs_s2 img,
.qs_s3 img {
  transition: transform 0.1s ease-out;
}

.qs_s3 img {
  margin-top: 80px;
}

/* SEÇÃO 3 */

.qs_s3 {
  display: flex;
  justify-content: center;
  gap: 5%;
  margin-bottom: 8%;
}

.qs_s3 img {
  width: 35%;
  border-radius: 15px;
}

.qs_s3_div1 {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #3d3d3d;
  font-weight: 200;
  font-size: 2.5vh;
}

.qs_s3_titulo {
  font-size: 5vh;
  color: var(--verde);
}

/* SEÇÃO 4 */

.qs_s4 {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.div_qs_s4 {
  width: 15%;
  color: #fff;
  padding: 15px 6px;
  font-weight: 200;
  text-align: center;
  border-radius: 15px;
  box-shadow: 10px 4px 80px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 10px;
  font-size: 17px;
}

.div_qs_s4 h3 {
  font-size: 30px;
  font-weight: 500;
}

.div_qs_s4:nth-child(1) {
  background-color: var(--verde);
}

.div_qs_s4:nth-child(2) {
  background-color: var(--vermelho);
}

.div_qs_s4:nth-child(3) {
  color: var(--verde);
}

.div_qs_s4:nth-child(4) {
  background-color: var(--vermelho);
}

.div_qs_s4:nth-child(5) {
  background-color: var(--verde);
}

/* SEÇÃO 5 */

.qs_s5 {
  margin-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qs_s5 h2 {
  color: var(--verde);
  font-size: 5vh;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.qs_s5 span {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  position: relative;
  margin: 0 7px;
}

.qs_s5 svg {
  position: absolute;
  top: 75%;
  left: 0;
  height: 20%;
  width: 100%;
  z-index: 1;
}

.qs_s5 p {
  margin-top: 30px;
  width: 40%;
  color: #3d3d3d;
  font-weight: 200;
  font-size: 2.5vh;
}

.span_sub_qs_s5 {
  z-index: 3;
}

/* SEÇÃO 6 */

.qs_s6 {
  position: relative;
  background-image: url("../../assets/img/qs_s6_img.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 6% 0;
}

.qs_s6::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #007943ce;
  z-index: 1;
}

.qs_s6 > * {
  position: relative;
  z-index: 2;
}

.qs_s6 h2 {
  width: 45%;
  font-size: 4.5vh;
  text-align: center;
}

.qs_s6 p {
  width: 50%;
  font-size: 2.5vh;
  text-align: center;
  font-weight: 200;
  margin-top: 20px;
}

/* SEÇÃO 7 */

.qs_s7 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 8%;
}

.qs_s7 h2 {
  color: var(--verde);
  font-size: 30px;
  width: 30%;
}

.link_conheca_prod {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-right: 10%;
  color: #e10f2e;
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
}

.seta {
  position: absolute;
  font-size: 20px;
  margin-right: 5px;
  opacity: 0;
  transition: 0.5s;
}

.seta2 {
  font-size: 20px;
  transition: opacity 0.3s ease;
  transition: 0.5s;
  margin-right: 5px;
}

.link_conheca_prod:hover .seta {
  display: block;
  position: inherit;
  opacity: 1;
}

.link_conheca_prod:hover .seta2 {
  position: absolute;
  opacity: 0;
}

.qs_s2_img_mobile,
.qs_s3_img_mobile {
  display: none;
}

/* RESPONSIVIDADE SEÇÃO 1: */
@media (max-width: 1370px) {
  .backgroundLavoura h1 {
    font-size: 40px;
    margin-left: 6%;
    margin-top: -10%;
  }
  .svg_seta {
    margin-left: 6%;
  }
}
@media (max-width: 550px) {
  .backgroundLavoura h1 {
    font-size: 30px;
    margin-top: -20%;
    width: 75%;
  }
  .backgroundLavoura::before {
    background-position: 65% center !important;
  }
  .svg_seta {
    margin-top: 20px;
  }
}

/* RESPONSIVIDADE SEÇÃO 2: */

@media (max-width: 1280px) {
  .qs_s2_img_mobile {
    display: block;
    margin-top: 20px;
  }
  .qs_s2_img {
    display: none;
  }
  .qs_s2 {
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }
  .qs_s2_div1 {
    width: 80%;
    margin-top: -5%;
    z-index: 1;
  }
  .qs_s2_titulo {
    font-size: 30px;
    margin-top: -5%;
    text-align: start;
  }
  .qs_s2 img {
    width: 50%;
  }
}
@media (max-width: 980px) {
  .qs_s2_titulo {
    margin-top: -10%;
  }
  .qs_s2 img {
    width: 80%;
  }
}
@media (max-width: 550px) {
  .qs_s2_div1 {
    padding-top: 0%;
  }
  .qs_s2_titulo {
    font-size: 25px;
    margin-top: -25%;
  }
  .qs_s2_div1 {
    font-size: 17px;
  }
}
@media (max-width: 470px) {
  .qs_s2_titulo {
    margin-top: -35%;
  }
}

/* RESPONSIVIDADE SEÇÃO 3: */
@media (max-width: 1280px) {
  .qs_s3_img_mobile {
    display: block;
  }
  .qs_s3_img {
    display: none;
  }
  .qs_s3 {
    flex-direction: column-reverse;
    align-items: center;
    text-align: justify;
  }
  .qs_s3_div1 {
    width: 80%;
  }
  .qs_s3_titulo {
    font-size: 30px;
    margin-top: -5%;
    text-align: start;
  }
  .qs_s3 img {
    width: 50%;
  }
}
@media (max-width: 980px) {
  .qs_s3 img {
    width: 80%;
  }
}
@media (max-width: 550px) {
  .qs_s3_titulo {
    font-size: 25px;
  }
  .qs_s3_div1 {
    font-size: 17px;
  }
}

/* RESPONSIVIDADE SEÇÃO 4: */

@media (max-width: 1100px) {
  .qs_s4 {
    flex-direction: column;
    align-items: center;
  }
  .div_qs_s4 {
    width: 50%;
  }
}
@media (max-width: 780px) {
  .div_qs_s4 {
    width: 70%;
  }
}
@media (max-width: 670px) {
  .div_qs_s4 {
    padding: 15px;
    font-size: 17px;
    margin: 0px;
  }
  .div_qs_s4 h3 {
    font-size: 22px;
    font-weight: 500;
  }
}

/* RESPONSIVIDADE SEÇÃO 5: */

@media (max-width: 1100px) {
  .qs_s5 p {
    width: 60%;
  }
}
@media (max-width: 550px) {
  .qs_s5 p {
    width: 80%;
  }
  .qs_s5 h2 {
    font-size: 30px;
  }
}

/* RESPONSIVIDADE SEÇÃO 6: */

@media (max-width: 870px) {
  .qs_s6 h2 {
    width: 60%;
    font-size: 28px;
  }
  
  .qs_s6 p {
    width: 80%;
    font-size: 18px;
  }
}
@media (max-width: 610px) {
  .qs_s6 h2 {
    width: 70%;
    font-size: 22px;
  }
  
  .qs_s6 p {
    font-size: 15px;
  }
}
@media (max-width: 390px) {
  .qs_s6 p {
    font-size: 14px;
  }
}


/* RESPONSIVIDADE SEÇÃO 7: */
@media (max-width: 950px) {
  .qs_s7 {
    flex-direction: column;
    gap: 40px;
  }
  .qs_s7 h2 {
    font-size: 25px;
    width: 80%;
  }
}
@media (max-width: 490px) {
  .qs_s7 h2 {
    font-size: 22px;
    margin-top: 20px;
  }
  .qs_s7 a {
    font-size: 18px;
    margin-bottom: 20px;
  }
}